// Import Main styles for this application
@import './scss/style.scss';

html, body, #root{
  height: 100%;
  font-family: Segoe UI;
  font-size: 14px;
}
.app {  
  height: auto;
}

.app-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.app-main {
  
  margin:auto;
  max-width: 500px;  
}

.app-header {
  padding-left: 19px;
  background-color: #01579B;
  height: 56px;
  width: 100%; 
}

.logo {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.app-logo{
  vertical-align: middle;
  max-height: 18px;
}