@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('/fonts/#{$icomoon-font-family}.eot?8i9rq3');
  src:  url('/fonts/#{$icomoon-font-family}.eot?8i9rq3#iefix') format('embedded-opentype'),
    url('/fonts/#{$icomoon-font-family}.ttf?8i9rq3') format('truetype'),
    url('/fonts/#{$icomoon-font-family}.woff?8i9rq3') format('woff'),
    url('/fonts/#{$icomoon-font-family}.svg?8i9rq3##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="solstice-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.solstice-icon-redo {
  &:before {
    content: $solstice-icon-redo; 
  }
}
.solstice-icon-undo {
  &:before {
    content: $solstice-icon-undo; 
  }
}
.solstice-icon-arrow-upward {
  &:before {
    content: $solstice-icon-arrow-upward; 
  }
}
.solstice-icon-begins-with {
  &:before {
    content: $solstice-icon-begins-with; 
  }
}
.solstice-icon-contains {
  &:before {
    content: $solstice-icon-contains; 
  }
}
.solstice-icon-does-not-contain {
  &:before {
    content: $solstice-icon-does-not-contain; 
  }
}
.solstice-icon-ends-with {
  &:before {
    content: $solstice-icon-ends-with; 
  }
}
.solstice-icon-info {
  &:before {
    content: $solstice-icon-info; 
  }
}
.solstice-icon-success {
  &:before {
    content: $solstice-icon-success; 
  }
}
.solstice-icon-information {
  &:before {
    content: $solstice-icon-information; 
  }
}
.solstice-icon-arrow-downward {
  &:before {
    content: $solstice-icon-arrow-downward; 
  }
}
.solstice-icon-appointment {
  &:before {
    content: $solstice-icon-appointment; 
  }
}
.solstice-icon-company {
  &:before {
    content: $solstice-icon-company; 
  }
}
.solstice-icon-contact {
  &:before {
    content: $solstice-icon-contact; 
  }
}
.solstice-icon-deal {
  &:before {
    content: $solstice-icon-deal; 
  }
}
.solstice-icon-note {
  &:before {
    content: $solstice-icon-note; 
  }
}
.solstice-icon-overview {
  &:before {
    content: $solstice-icon-overview; 
  }
}
.solstice-icon-task {
  &:before {
    content: $solstice-icon-task; 
  }
}
.solstice-icon-file {
  &:before {
    content: $solstice-icon-file; 
  }
}
.solstice-icon-person {
  &:before {
    content: $solstice-icon-person; 
  }
}
.solstice-icon-strike-through {
  &:before {
    content: $solstice-icon-strike-through; 
  }
}
.solstice-icon-connect-to-data {
  &:before {
    content: $solstice-icon-connect-to-data; 
  }
}
.solstice-icon-bar-chart {
  &:before {
    content: $solstice-icon-bar-chart; 
  }
}
.solstice-icon-doughnat-chart {
  &:before {
    content: $solstice-icon-doughnat-chart; 
  }
}
.solstice-icon-pie-chart {
  &:before {
    content: $solstice-icon-pie-chart; 
  }
}
.solstice-icon-table-chart {
  &:before {
    content: $solstice-icon-table-chart; 
  }
}
.solstice-icon-add {
  &:before {
    content: $solstice-icon-add; 
  }
}
.solstice-icon-bookmark {
  &:before {
    content: $solstice-icon-bookmark; 
  }
}
.solstice-icon-delete {
  &:before {
    content: $solstice-icon-delete; 
  }
}
.solstice-icon-edit {
  &:before {
    content: $solstice-icon-edit; 
  }
}
.solstice-icon-open-in-new {
  &:before {
    content: $solstice-icon-open-in-new; 
  }
}
.solstice-icon-save {
  &:before {
    content: $solstice-icon-save; 
  }
}
.solstice-icon-grid-view {
  &:before {
    content: $solstice-icon-grid-view; 
  }
}
.solstice-icon-list-view {
  &:before {
    content: $solstice-icon-list-view; 
  }
}
.solstice-icon-visibility {
  &:before {
    content: $solstice-icon-visibility; 
  }
}
.solstice-icon-visibility-off {
  &:before {
    content: $solstice-icon-visibility-off; 
  }
}
.solstice-icon-align-center {
  &:before {
    content: $solstice-icon-align-center; 
  }
}
.solstice-icon-align-justify {
  &:before {
    content: $solstice-icon-align-justify; 
  }
}
.solstice-icon-align-left {
  &:before {
    content: $solstice-icon-align-left; 
  }
}
.solstice-icon-align-right {
  &:before {
    content: $solstice-icon-align-right; 
  }
}
.solstice-icon-bold {
  &:before {
    content: $solstice-icon-bold; 
  }
}
.solstice-icon-clear-format {
  &:before {
    content: $solstice-icon-clear-format; 
  }
}
.solstice-icon-color-fill {
  &:before {
    content: $solstice-icon-color-fill; 
  }
}
.solstice-icon-color-text {
  &:before {
    content: $solstice-icon-color-text; 
  }
}
.solstice-icon-copy {
  &:before {
    content: $solstice-icon-copy; 
  }
}
.solstice-icon-cut {
  &:before {
    content: $solstice-icon-cut; 
  }
}
.solstice-icon-decimal {
  &:before {
    content: $solstice-icon-decimal; 
  }
}
.solstice-icon-hr {
  &:before {
    content: $solstice-icon-hr; 
  }
}
.solstice-icon-image {
  &:before {
    content: $solstice-icon-image; 
  }
}
.solstice-icon-indent {
  &:before {
    content: $solstice-icon-indent; 
  }
}
.solstice-icon-italic {
  &:before {
    content: $solstice-icon-italic; 
  }
}
.solstice-icon-link {
  &:before {
    content: $solstice-icon-link; 
  }
}
.solstice-icon-outdent {
  &:before {
    content: $solstice-icon-outdent; 
  }
}
.solstice-icon-paste {
  &:before {
    content: $solstice-icon-paste; 
  }
}
.solstice-icon-select-all {
  &:before {
    content: $solstice-icon-select-all; 
  }
}
.solstice-icon-smiley {
  &:before {
    content: $solstice-icon-smiley; 
  }
}
.solstice-icon-subscript {
  &:before {
    content: $solstice-icon-subscript; 
  }
}
.solstice-icon-superscript {
  &:before {
    content: $solstice-icon-superscript; 
  }
}
.solstice-icon-text {
  &:before {
    content: $solstice-icon-text; 
  }
}
.solstice-icon-underline {
  &:before {
    content: $solstice-icon-underline; 
  }
}
.solstice-icon-facebook {
  &:before {
    content: $solstice-icon-facebook; 
  }
}
.solstice-icon-linkedIn {
  &:before {
    content: $solstice-icon-linkedIn; 
  }
}
.solstice-icon-skype {
  &:before {
    content: $solstice-icon-skype; 
  }
}
.solstice-icon-twitter {
  &:before {
    content: $solstice-icon-twitter; 
  }
}
.solstice-icon-www {
  &:before {
    content: $solstice-icon-www; 
  }
}
.solstice-icon-folder-open {
  &:before {
    content: $solstice-icon-folder-open; 
  }
}
.solstice-icon-folder {
  &:before {
    content: $solstice-icon-folder; 
  }
}
.solstice-icon-notification {
  &:before {
    content: $solstice-icon-notification; 
  }
}
.solstice-icon-warning {
  &:before {
    content: $solstice-icon-warning; 
  }
}
.solstice-icon-error {
  &:before {
    content: $solstice-icon-error; 
  }
}
.solstice-icon-drag-indicator {
  &:before {
    content: $solstice-icon-drag-indicator; 
  }
}
.solstice-icon-not-empty {
  &:before {
    content: $solstice-icon-not-empty; 
  }
}
.solstice-icon-pronaunce-like {
  &:before {
    content: $solstice-icon-pronaunce-like; 
  }
}
.solstice-icon-greater-than-or-equal {
  &:before {
    content: $solstice-icon-greater-than-or-equal; 
  }
}
.solstice-icon-greater-than {
  &:before {
    content: $solstice-icon-greater-than; 
  }
}
.solstice-icon-less-than-or-equal {
  &:before {
    content: $solstice-icon-less-than-or-equal; 
  }
}
.solstice-icon-less-than {
  &:before {
    content: $solstice-icon-less-than; 
  }
}
.solstice-icon-empty {
  &:before {
    content: $solstice-icon-empty; 
  }
}
.solstice-icon-not-equal {
  &:before {
    content: $solstice-icon-not-equal; 
  }
}
.solstice-icon-equal {
  &:before {
    content: $solstice-icon-equal; 
  }
}
.solstice-icon-more-horizontal {
  &:before {
    content: $solstice-icon-more-horizontal; 
  }
}
.solstice-icon-refresh {
  &:before {
    content: $solstice-icon-refresh; 
  }
}
.solstice-icon-more-vertical {
  &:before {
    content: $solstice-icon-more-vertical; 
  }
}
.solstice-icon-calendar-today {
  &:before {
    content: $solstice-icon-calendar-today; 
  }
}
.solstice-icon-star {
  &:before {
    content: $solstice-icon-star; 
  }
}
.solstice-icon-arrow-drop-down {
  &:before {
    content: $solstice-icon-arrow-drop-down; 
  }
}
.solstice-icon-arrow-right {
  &:before {
    content: $solstice-icon-arrow-right; 
  }
}
.solstice-icon-check {
  &:before {
    content: $solstice-icon-check; 
  }
}
.solstice-icon-chevron-down {
  &:before {
    content: $solstice-icon-chevron-down; 
  }
}
.solstice-icon-chevron-left {
  &:before {
    content: $solstice-icon-chevron-left; 
  }
}
.solstice-icon-chevron-right {
  &:before {
    content: $solstice-icon-chevron-right; 
  }
}
.solstice-icon-chevron-up {
  &:before {
    content: $solstice-icon-chevron-up; 
  }
}
.solstice-icon-Close {
  &:before {
    content: $solstice-icon-Close; 
  }
}
.solstice-icon-help {
  &:before {
    content: $solstice-icon-help; 
  }
}
.solstice-icon-menu {
  &:before {
    content: $solstice-icon-menu; 
  }
}
.solstice-icon-search {
  &:before {
    content: $solstice-icon-search; 
  }
}
.solstice-icon-settings {
  &:before {
    content: $solstice-icon-settings; 
  }
}
.solstice-icon-excel {
  &:before {
    content: $solstice-icon-excel; 
  }
}
.solstice-icon-pdf {
  &:before {
    content: $solstice-icon-pdf; 
  }
}
.solstice-icon-word {
  &:before {
    content: $solstice-icon-word; 
  }
}

