.form{
    width: 490px;
    padding: 0 80px 0 70px;
    .form-control{
       height: 30px;
       width: 300px;
       border: 1px solid #E0E0E0;
       box-sizing: border-box;
       border-radius: 2px;
    }

    label{
        text-align: left;        
        line-height: 19px;
        color: #757575;
        .required-mark{
            color: red;
        }
    }
    .submit{
        //background: #2962FF;
        border-radius: 2px;
        width: 300px;
        
        box-sizing: border-box;        
        line-height: 20px;
        /* identical to box height, or 143% */

        text-align: center;
        color: #FFFFFF;

        
        &.disabled{
            background-color: #F5F5F5;
            border: 1px solid #EEEEEE;
            /* Text/Disabled Color */
            color: #757575;
        }
        

        
    }
}
.form-header{
    text-align: center;
}