$icomoon-font-family: "solstice-icons" !default;
$icomoon-font-path: "fonts" !default;

$solstice-icon-redo: "\e938";
$solstice-icon-undo: "\e939";
$solstice-icon-arrow-upward: "\e963";
$solstice-icon-begins-with: "\e95f";
$solstice-icon-contains: "\e960";
$solstice-icon-does-not-contain: "\e961";
$solstice-icon-ends-with: "\e962";
$solstice-icon-info: "\e95d";
$solstice-icon-success: "\e95e";
$solstice-icon-information: "\e922";
$solstice-icon-arrow-downward: "\e95c";
$solstice-icon-appointment: "\e913";
$solstice-icon-company: "\e914";
$solstice-icon-contact: "\e915";
$solstice-icon-deal: "\e957";
$solstice-icon-note: "\e958";
$solstice-icon-overview: "\e959";
$solstice-icon-task: "\e95a";
$solstice-icon-file: "\e95b";
$solstice-icon-person: "\e911";
$solstice-icon-strike-through: "\e949";
$solstice-icon-connect-to-data: "\e92d";
$solstice-icon-bar-chart: "\e92e";
$solstice-icon-doughnat-chart: "\e92f";
$solstice-icon-pie-chart: "\e930";
$solstice-icon-table-chart: "\e931";
$solstice-icon-add: "\e932";
$solstice-icon-bookmark: "\e933";
$solstice-icon-delete: "\e934";
$solstice-icon-edit: "\e935";
$solstice-icon-open-in-new: "\e936";
$solstice-icon-save: "\e937";
$solstice-icon-grid-view: "\e93a";
$solstice-icon-list-view: "\e93b";
$solstice-icon-visibility: "\e93c";
$solstice-icon-visibility-off: "\e93d";
$solstice-icon-align-center: "\e93e";
$solstice-icon-align-justify: "\e93f";
$solstice-icon-align-left: "\e940";
$solstice-icon-align-right: "\e941";
$solstice-icon-bold: "\e942";
$solstice-icon-clear-format: "\e943";
$solstice-icon-color-fill: "\e944";
$solstice-icon-color-text: "\e945";
$solstice-icon-copy: "\e946";
$solstice-icon-cut: "\e947";
$solstice-icon-decimal: "\e948";
$solstice-icon-hr: "\e94a";
$solstice-icon-image: "\e94b";
$solstice-icon-indent: "\e94c";
$solstice-icon-italic: "\e94d";
$solstice-icon-link: "\e94e";
$solstice-icon-outdent: "\e94f";
$solstice-icon-paste: "\e950";
$solstice-icon-select-all: "\e951";
$solstice-icon-smiley: "\e952";
$solstice-icon-subscript: "\e953";
$solstice-icon-superscript: "\e954";
$solstice-icon-text: "\e955";
$solstice-icon-underline: "\e956";
$solstice-icon-facebook: "\e928";
$solstice-icon-linkedIn: "\e929";
$solstice-icon-skype: "\e92a";
$solstice-icon-twitter: "\e92b";
$solstice-icon-www: "\e92c";
$solstice-icon-folder-open: "\e926";
$solstice-icon-folder: "\e927";
$solstice-icon-notification: "\e925";
$solstice-icon-warning: "\e923";
$solstice-icon-error: "\e924";
$solstice-icon-drag-indicator: "\e921";
$solstice-icon-not-empty: "\e91d";
$solstice-icon-pronaunce-like: "\e918";
$solstice-icon-greater-than-or-equal: "\e919";
$solstice-icon-greater-than: "\e91a";
$solstice-icon-less-than-or-equal: "\e91b";
$solstice-icon-less-than: "\e91c";
$solstice-icon-empty: "\e91e";
$solstice-icon-not-equal: "\e91f";
$solstice-icon-equal: "\e920";
$solstice-icon-more-horizontal: "\e917";
$solstice-icon-refresh: "\e916";
$solstice-icon-more-vertical: "\e912";
$solstice-icon-calendar-today: "\e910";
$solstice-icon-star: "\e90f";
$solstice-icon-arrow-drop-down: "\e903";
$solstice-icon-arrow-right: "\e904";
$solstice-icon-check: "\e905";
$solstice-icon-chevron-down: "\e906";
$solstice-icon-chevron-left: "\e907";
$solstice-icon-chevron-right: "\e908";
$solstice-icon-chevron-up: "\e909";
$solstice-icon-Close: "\e90a";
$solstice-icon-help: "\e90b";
$solstice-icon-menu: "\e90c";
$solstice-icon-search: "\e90d";
$solstice-icon-settings: "\e90e";
$solstice-icon-excel: "\e900";
$solstice-icon-pdf: "\e901";
$solstice-icon-word: "\e902";

$solstice-icon-size-lg: 32px;

