.app-loading {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  
    .loading-indicator {
        position: absolute;
        top: 0;
        left: 0;
    }
  
    .spinner {
        width: 50px !important;
        height: 50px !important;
        margin: 20px auto 0 auto;
        position: relative;
        -webkit-animation: rotation .6s infinite linear;
        -moz-animation: rotation .6s infinite linear;
        -o-animation: rotation .6s infinite linear;
        animation: rotation .6s infinite linear;
        border-left: 6px solid blue;
        border-right: 6px solid #D8D8D8;
        border-bottom: 6px solid #D8D8D8;
        border-top: 6px solid #D8D8D8;
        border-radius: 100%;
    }
  }
  
  @-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
  
    to {
        -webkit-transform: rotate(359deg);
    }
  }
  
  @-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }
  
    to {
        -moz-transform: rotate(359deg);
    }
  }
  
  @-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
    }
  
    to {
        -o-transform: rotate(359deg);
    }
  }
  
  @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
  
    to {
        transform: rotate(359deg);
    }
  }